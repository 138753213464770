import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "passwordreset-container" }
const _hoisted_2 = { class: "passwordreset-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "ar-field" }
const _hoisted_6 = { class: "formlabel" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_9 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_10 = { class: "ar-field" }
const _hoisted_11 = { class: "formlabel" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_14 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_15 = {
  key: 1,
  class: "password-confirmed"
}
const _hoisted_16 = { href: "/login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHeader, { defaultHeader: true }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('header.pw_reset')), 1)
        ]),
        _: 1
      }),
      (!_ctx.confirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("form", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('form.newPassword')), 1),
                _withDirectives(_createElementVNode("input", {
                  name: "email",
                  type: "password",
                  placeholder: _ctx.$t('form.newPassword'),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
                  autocomplete: "off"
                }, null, 8, _hoisted_7), [
                  [_vModelText, _ctx.form.password]
                ]),
                (_ctx.errorClass('password', _ctx.form.password)=='required')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('error.required')), 1))
                  : (_ctx.errorClass('password', _ctx.form.password)=='toShort')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('error.pw_too_short')), 1))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('form.newPasswordRepeat')), 1),
                _withDirectives(_createElementVNode("input", {
                  name: "password",
                  type: "password",
                  placeholder: _ctx.$t('form.newPasswordRepeat'),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordRepeat) = $event)),
                  autocomplete: "off"
                }, null, 8, _hoisted_12), [
                  [_vModelText, _ctx.passwordRepeat]
                ]),
                (_ctx.errorClass('passwordRepeat', _ctx.passwordRepeat)=='required')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('error.required')), 1))
                  : _createCommentVNode("", true),
                (_ctx.errorClass('passwordRepeat', _ctx.passwordRepeat)=='dontMatch')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('error.dontMatch')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_Button, {
                text: _ctx.$t('button.resetPW'),
                waitForResponse: _ctx.waitForResponse,
                onHandleClicked: _ctx.submitForm
              }, null, 8, ["text", "waitForResponse", "onHandleClicked"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('pwResetFinished')) + ".", 1),
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('pwChangeSuccess')) + " ", 1),
              _createElementVNode("a", _hoisted_16, _toDisplayString(_ctx.$t('here')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('login')) + ".", 1)
            ])
          ]))
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error
    }, null, 8, ["show", "text", "error"])
  ]))
}